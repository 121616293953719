// import { request } from './request'

// 获取左侧菜单列表
export function getMenuListRequest () {
  return new Promise(function (resolve, reject) {
    // 做一些异步操作
    resolve({
      data: [
        {
          id: 80,
          authName: '数据统计',
          path: 'statistics',
          children: [
            {
              id: 81,
              authName: '用户统计',
              path: 'statUser',
              children: [],
              order: null
            },
            {
              id: 83,
              authName: '抽奖统计',
              path: 'statLottery',
              children: [],
              order: null
            },
            {
              id: 84,
              authName: '分享统计',
              path: 'statShare',
              children: [],
              order: null
            }
            /* {
              id: 145,
              authName: 'CNZZ明细',
              path: 'cnzz',
              children: [],
              order: null
            },
            {
              id: 146,
              authName: '实时占比',
              path: 'real',
              children: [],
              order: null
            },
            {
              id: 147,
              authName: '花费统计',
              path: 'cost',
              children: [],
              order: null
            },
            {
              id: 148,
              authName: '站点管理',
              path: 'site',
              children: [],
              order: null
            } */
          ],
          order: 10
        },
        {
          id: 90,
          authName: '用户管理',
          path: 'users',
          children: [
            {
              id: 91,
              authName: '用户列表',
              path: 'users',
              children: [],
              order: null
            },
            {
              id: 92,
              authName: '系统用户',
              path: 'systemUsers',
              children: [],
              order: null
            },
            {
              id: 93,
              authName: '店长申请',
              path: 'userTeamApply',
              children: [],
              order: null
            }
          ],
          order: 9
        },
        {
          id: 220,
          authName: '客服管理',
          path: 'customer',
          children: [
            {
              id: 221,
              authName: '创建客服',
              path: 'customer',
              children: [],
              order: 1
            },
            {
              id: 222,
              authName: '热门咨询',
              path: 'knowledge',
              children: [],
              order: 2
            }
          ],
          order: 10
        },
        {
          id: 235,
          authName: '任务管理',
          path: 'task',
          children: [
            {
              id: 243,
              authName: '签到规则',
              path: 'sign',
              children: [],
              order: null
            },
            {
              id: 244,
              authName: '任务定义',
              path: 'task',
              children: [],
              order: null
            },
            {
              id: 245,
              authName: '夺宝',
              path: 'hunt',
              children: [],
              order: null
            },
            {
              id: 246,
              authName: '大转盘',
              path: 'lottery',
              children: [],
              order: null
            },
            {
              id: 247,
              authName: '拆红包',
              path: 'red',
              children: [],
              order: null
            },
            {
              id: 248,
              authName: '分享配置',
              path: 'share',
              children: [],
              order: null
            },
            {
              id: 249,
              authName: '邀请审核',
              path: 'inviteAuditRecord',
              children: [],
              order: null
            }
          ],
          order: 9
        },
        {
          id: 101,
          authName: '商品管理',
          path: 'goods',
          children: [
            {
              id: 10,
              authName: '店铺管理',
              path: 'shop',
              children: [],
              order: 10
            },
            {
              id: 20,
              authName: '服务保障',
              path: 'term',
              children: [],
              order: 20
            },
            {
              id: 30,
              authName: '商品分类',
              path: 'categories',
              children: [],
              order: 30
            },
            {
              id: 40,
              authName: '分类属性',
              path: 'attribute',
              children: [],
              order: 40
            },
            {
              id: 50,
              authName: '添加商品',
              path: 'add',
              children: [],
              order: 50
            },
            {
              id: 60,
              authName: '商品列表',
              path: 'goods',
              children: [],
              order: 60
            },
            {
              id: 70,
              authName: '价格返利',
              path: 'price',
              children: [],
              order: 60
            },
            {
              id: 71,
              authName: '评论配置',
              path: 'goodsComment',
              children: [],
              order: 61
            }
          ],
          order: 30
        },
        {
          id: 160,
          authName: '营销管理',
          path: 'market',
          children: [
            {
              id: 161,
              authName: '优惠券管理',
              path: 'couponList',
              children: [],
              order: 10
            },
            {
              id: 162,
              authName: '页面定义',
              path: 'pageDefine',
              children: [],
              order: 10
            },
            {
              id: 163,
              authName: '页面展示',
              path: 'pageShow',
              children: [],
              order: 11
            },
            {
              id: 164,
              authName: '推广商品',
              path: 'adGoods',
              children: [],
              order: 12
            }
          ],
          order: 31
        },
        {
          id: 102,
          authName: '订单管理',
          path: 'orders',
          children: [
            {
              id: 106,
              authName: '订单设置',
              path: 'orderSetting',
              children: [],
              order: null
            },
            {
              id: 107,
              authName: '订单列表',
              path: 'orders',
              children: [],
              order: null
            },
            {
              id: 108,
              authName: '交易明细',
              path: 'trades',
              children: [],
              order: null
            },
            {
              id: 109,
              authName: '退货设置',
              path: 'refund',
              children: [],
              order: null
            },
            {
              id: 110,
              authName: '退货申请',
              path: 'refunds',
              children: [],
              order: null
            },
            {
              id: 111,
              authName: '快递管理',
              path: 'express',
              children: [],
              order: null
            }
          ],
          order: 40
        },
        {
          id: 202,
          authName: '提现管理',
          path: 'withdraw',
          children: [
            {
              id: 206,
              authName: '提现配置',
              path: 'withdrawSetting',
              children: [],
              order: null
            },
            {
              id: 207,
              authName: '提现列表',
              path: 'withdrawList',
              children: [],
              order: null
            }
          ]
        },
        {
          id: 115,
          authName: '供应链管理',
          path: 'supply',
          children: [
            {
              id: 116,
              authName: '供应商管理',
              path: 'supplier',
              children: [],
              order: null
            },
            {
              id: 117,
              authName: '数据同步',
              path: 'sync',
              children: [],
              order: null
            },
            {
              id: 118,
              authName: '品牌列表',
              path: 'brand',
              children: [],
              order: null
            },
            {
              id: 119,
              authName: '分类列表',
              path: 'supply-category',
              children: [],
              order: null
            }
          ]
        },
        {
          id: 150,
          authName: '系统管理',
          path: 'system',
          children: [
            {
              id: 151,
              authName: '系统消息',
              path: 'notify',
              children: [],
              order: null
            },
            {
              id: 152,
              authName: '系统配置',
              path: 'config',
              children: [],
              order: null
            }
          ]
        }
      ],
      meta: {
        msg: '获取菜单列表成功',
        status: 200
      }
    })
  })
}
